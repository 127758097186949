import { ref } from "vue"
import { defineStore } from "pinia"
import { i18n } from "@/modules/i18n/i18nConfig"

export interface Options {
  severity?: number
  title: string
  line1?: string
  line2?: string
  persistent?: boolean
}

export const useNotificationStore = defineStore("notificationStore", () => {
  const notificationIsOpen = ref(false)

  const notificationOptions = ref({} as Options)

  function openNotification(options: Options) {
    notificationIsOpen.value = true
    notificationOptions.value = options
  }

  function closeNotification(closeTimeout = 0) {
    window.setTimeout(() => {
      notificationIsOpen.value = false
    }, closeTimeout)
  }

  function openErrorNotification(message: string) {
    openNotification({
      severity: 3,
      title: i18n.global.t("general.error"),
      line1: message,
    })
  }

  function openInfoNotification(message: string, title: string, persistent?: boolean) {
    openNotification({
      severity: 1,
      title: title,
      line1: message,
      persistent: persistent,
    })
  }

  function openAttentionNotification(message: string, title: string, persistent?: boolean) {
    openNotification({
      severity: 2,
      title: title,
      line1: message,
      persistent: persistent,
    })
  }
  // function autoCloseNotification(autoCloseTimeout = 5000) {
  //   window.setTimeout(() => {notificationIsOpen.value = false}, autoCloseTimeout)
  // }

  return {
    notificationIsOpen,
    openNotification,
    closeNotification,
    notificationOptions,
    openErrorNotification,
    openInfoNotification,
    openAttentionNotification,
  }
})
